

















































































































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import EditEmergencyFundDefineByViewModel from
  '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-emergency-fund-goal-plan/edit-emergency-fund-define-by-view-model';

@Component({
  name: 'EditEmergencyFundDefineBy',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class EditEmergencyFundDefineBy extends Vue {
  edit_emergency_fund_define_by_view_model = Vue.observable(
    new EditEmergencyFundDefineByViewModel(this),
  );

  async mounted() {
    await this.edit_emergency_fund_define_by_view_model.initialize();
  }
}
